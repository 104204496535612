import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { AuthTokenVerify, UpdateBearer } from "../../reusable/AuthUtils";
import Notification, {errorBorder, errorText} from '../../reusable/Notification';
import { PageToTop, returnOnlyDefined } from "../../reusable/CommonUtils";
import DatePicker from "react-datepicker";
import TimePicker from 'rc-time-picker';
import { confirm } from '../../reusable/Confirmation';
import { format } from "date-fns";
import { useParams } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import 'rc-time-picker/assets/index.css';

const ErfqAdd = ({history}) => {

    let { id } = useParams();

    const initFieldError = {
        erfq_form: {},
        erfq_form_documents: {},
        erfq_form_items: {},
        erfq_form_terms_condition: {},
        emails: ''
    };

    const [ filedError, setFieldError ] = useState(initFieldError);
    const [ alertMessage, setAlertMessage ] = useState({});
    const [ disableSubmit, setDisableSubmit ] = useState(false);

    const emailInput = useRef();
    const fileInput = useRef([]);

    const initItem = {
        item_code: '',
        item_qty: '',
        item_uom: '',
        description: '',
    };

    const initDocument = {
        file_name: '',
        document: '',
    };

    const initTermsCondition = {
        terms_condition: ''
    };

    const initForm = {
        bidding_type: '',
        start_date: new Date(),
        start_time: '',
        end_date: new Date(),
        end_time: '',
        finalize_date: '',
        currency: '',
        delivery_place: '',
        incoterms: '',
        bidding_nature: '',
        subject: '',
        pr_number: '',
        pr_date: '',
        rfq_number: '',
        delivery_date: '',
        price_validity: '',
        specific_end_use: '',
        requesting_dept: '',
        prepared_by: '',
        approved_by: '',
        emails: [],
        items: [initItem],
        documents: [initDocument],
        terms_condition: [initTermsCondition]
    };

    const [formMeta, setFormMeta] = useState({
        bidding_type: [],
        bidding_nature: [],
        incoterms: [],
        item_uom: [],
        currencies: []
    });

    const [formData, setFormData] = useState(initForm);

    useEffect(() => {
        AuthTokenVerify(history);
        PageToTop();
    }, []);

    
    useEffect(() => {
        axios.get("erfq/form/").then((response) => {
            setFormMeta(response.data);
            UpdateBearer(history, response);
        }).catch((error) => {
            //
         });
    }, []);

    const renderBuddingType = () => {
        const content = [<option value="0">-select-</option>];
        formMeta.bidding_type.map(item => {
            content.push(<option value={item.pk}>{item.meta_value}</option>);
        });
        return content;
    };

    const renderIncoterms = () => {
        const content = [<option value="0">-select-</option>];
        formMeta.incoterms.map(item => {
            content.push(<option value={item.pk}>{item.meta_value}</option>);
        });
        return content;
    };

    const renderBiddingNature = () => {
        const content = [<option value="0">-select-</option>];
        formMeta.bidding_nature.map(item => {
            content.push(<option value={item.pk}>{item.meta_value}</option>);
        });
        return content;
    };

    const renderItemUom = () => {
        const content = [<option value="0">-select-</option>];
        formMeta.item_uom.map(item => {
            content.push(<option value={item.pk}>{item.meta_value}</option>);
        });
        return content;
    };

    const renderCurrencies = () => {
        const content = [<option value="0">-select-</option>];
        formMeta.currencies.map(item => {
            content.push(<option value={item.pk}>{item.code} - {item.name}</option>);
        });
        return content;
    };

    const deleteEmail = async(index) => {
        const result = await confirm('Are you sure to delete this?');
        if(!result) return;
        const temp_list = formData.emails;
        temp_list.splice(index, 1);
        setFormData({...formData, 'emails' : temp_list});
    };

    const addEmail = () => {
        setFieldError(initFieldError);
        const temp_list = formData.emails;
        const email = emailInput.current.value;
        const is_valid_email = email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        let error_msg = '';

        if(!is_valid_email){
            error_msg = 'Invalid Email Address!';
        }else if(temp_list.includes(email)){
            error_msg = 'Email Already Exist!';
        }

        if(error_msg){
            setFieldError({...filedError, emails: error_msg});
            return;
        }
        
        temp_list.push(email)
        setFormData({...formData, emails: temp_list});
        emailInput.current.value = '';
    };

    const renderEmails = () => {
        const content = [];
        formData.emails.map( (item, index) => {
            content.push(<li>{item} <i onClick={ () => deleteEmail(index) } class="bi bi-x"></i></li>);
        });
        return content;
    };

    const removeItem = async(index) => {
        const result = await confirm('Are you sure to delete this?');
        if(!result) return;
        const temp_list = formData.items;
        temp_list.splice(index, 1);
        setFormData({...formData, items: temp_list});
    };


    const addItem = () => {
        const temp_list = formData.items;
        temp_list.push(initItem);
        setFormData({...formData, items: temp_list});
    };

    const removeDocument = async(index) => {
        const result = await confirm('Are you sure to delete this?');
        if(!result) return;
        const temp_list = formData.documents;
        temp_list.splice(index, 1);
        setFormData({...formData, documents: temp_list});
    };

    const addDocument = () => {
        const temp_list = formData.documents;
        temp_list.push(initDocument);
        setFormData({...formData, documents: temp_list});
    };

    const removeTermsCondition = async(index) => {
        const result = await confirm('Are you sure to delete this?');
        if(!result) return;
        const temp_list = formData.terms_condition;
        temp_list.splice(index, 1);
        setFormData({...formData, terms_condition: temp_list});
    };

    const addTermsCondition = () => {
        const temp_list = formData.terms_condition;
        temp_list.push(initTermsCondition);
        setFormData({...formData, terms_condition: temp_list});
    };

    const handleItemChange = (e, index) => {
        var targetData = '';

        switch (e.target.name) {
            default:
                targetData = e.target.value;
                break;
        }

        var items = formData.items;
        items[index][e.target.name] = targetData;
        setFormData({...formData, ['items'] : items});
    };

    const handleDocumentChange = (e, index) => {
        var targetData = '';

        switch (e.target.name) {
            case "document":
                targetData = e.target.files[0];
                break;
            default:
                targetData = e.target.value;
                break;
        }

        var documents = formData.documents;
        documents[index][e.target.name] = targetData;
        setFormData({...formData, ['documents'] : documents});
    };


    const handleTermsConditionChange = (e, index) => {
        var targetData = '';

        switch (e.target.name) {
            default:
                targetData = e.target.value;
                break;
        }

        var terms_condition = formData.terms_condition;
        terms_condition[index][e.target.name] = targetData;
        setFormData({...formData, ['terms_condition'] : terms_condition});
    };

    const renderItems = () => {
        const content = [];
        let inc = 1;
        formData.items.map( (item, index) => {
            content.push(<tr>
                <td className="input-small text-center">
                    { inc }
                </td>
                <td className="input-small">
                    <input type="text" className="form-control input-small" name="item_code" value={item.item_code} onChange={(e) => handleItemChange(e, index) } />
                    { errorText(returnOnlyDefined(filedError, 'object.erfq_form_items[' + index + '].item_code')) }
                </td>
                
                <td className="input-small">
                    <input type="number" className="form-control" name="item_qty" value={item.item_qty} onChange={(e) => handleItemChange(e, index) }  />
                    { errorText(returnOnlyDefined(filedError, 'object.erfq_form_items[' + index + '].item_qty')) }
                </td>
                <td className="input-small">
                    <select className="form-select" name="item_uom" value={item.item_uom} onChange={(e) => handleItemChange(e, index) }>
                        { renderItemUom() }
                    </select>
                    { errorText(returnOnlyDefined(filedError, 'object.erfq_form_items[' + index + '].item_uom')) }
                </td>
                <td>
                    <textarea className="form-control" cols="4" name="description" value={item.description} onChange={(e) => handleItemChange(e, index) }></textarea>
                    { errorText(returnOnlyDefined(filedError, 'object.erfq_form_items[' + index + '].description')) }
                </td>
                <td className="input-small text-center sym-icon">
                {(() => {
                    if(inc == 1){
                        return (
                            <i className="bi bi-plus-circle" onClick={ () => addItem() }></i>
                        );
                    }

                    return (
                        <>
                            <i className="bi bi-dash-circle" onClick={ () => removeItem(index) }></i>&nbsp;
                            <i className="bi bi-plus-circle" onClick={ () => addItem() }></i>
                        </>
                    );
                })()}
                </td>
            </tr>);
            inc++;
        });
        return content;
    };

    const renderDocuments = () => {
        const content = [];
        formData.documents.map( (item, index) => {
            content.push(
                <div className="row mb-3 row-cols-1 row-cols-lg-3 single-col">
                    <div className="col">
                        <input type="text" className="form-control"  name="file_name" value={item.file_name} onChange={(e) => handleDocumentChange(e, index) }/>
                        { errorText(returnOnlyDefined(filedError, 'object.erfq_form_documents[' + index + '].file_name')) }
                    </div>
                    
                    <div className="col">
                        <input type="file" className="form-control" ref={(element) => {fileInput.current[index] = element}}  name="document" onChange={(e) => handleDocumentChange(e, index) } />
                        { errorText(returnOnlyDefined(filedError, 'object.erfq_form_documents[' + index + '].document')) }
                    </div>
                    <div className="col-1 plus-icon">
                        {(() => {
                            return (
                                <span>
                                    <i className="bi bi-dash-circle" onClick={ () => removeDocument(index) }></i>&nbsp;
                                    <i className="bi bi-plus-circle" onClick={ () => addDocument() }></i>
                                </span>
                            );
                        })()}
                    </div>
                </div>
            );
        });
        return content;
    };

    const renderTermsCondition = () => {

        const content = [];
        formData.terms_condition.map( (item, index) => {
            content.push(
                <div className="row mb-3 row-cols-1 row-cols-lg-2 single-col">
                   <div className="col-lg-8">
                        <textarea cols="4" className="form-control" name="terms_condition" 
                        value={item.terms_condition} 
                        onChange={(e) => handleTermsConditionChange(e, index) }></textarea>
                        { errorText(returnOnlyDefined(filedError, 'object.erfq_form_terms_condition[' + index + '].terms_condition')) }
                    </div>
                    <div className="col-1 plus-icon">
                        {(() => {
                            if(index == 0){
                                return (
                                    <i className="bi bi-plus-circle" onClick={ () => addTermsCondition() }></i>
                                );
                            }

                            return (
                                <span>
                                    <i className="bi bi-dash-circle" onClick={ () => removeTermsCondition(index) }></i>&nbsp;
                                    <i className="bi bi-plus-circle" onClick={ () => addTermsCondition() }></i>
                                </span>
                            );
                        })()}
                    </div>
                </div>
            );
        });
        return content;
    }

    const handleChange = (e) => {
        var targetData = '';

        switch (e.target.name) {
            default:
                targetData = e.target.value;
                break;
        }

        setFormData({...formData, [e.target.name] : targetData});
    };


    const onSubmit = (e) => {
        e.preventDefault();
        setAlertMessage({});
        setFieldError(initFieldError);
        setDisableSubmit(true);

        let fData = new FormData();
        Object.entries(formData).forEach(([k,v]) => {
            if(k == 'documents'){
                let doc_names = {};
                v.map( (item, index) => {
                    doc_names[index] = item.file_name;
                    fData.append('doc_files_' + index, item.document);
                });

                fData.append('doc_names', JSON.stringify(doc_names));

            }else if(['start_date', 'end_date', 'finalize_date', 'delivery_date', 'pr_date'].includes(k)){
                   v = v ? format(new Date(v), "yyyy-MM-dd") : '';
                   fData.append(k, v);
            }else if(['start_time', 'end_time'].includes(k)){
                v = v ? v.format('h:mm a') : '';
                fData.append(k, v);
            }
            else if(['emails', 'items', 'terms_condition'].includes(k)){
                fData.append(k, JSON.stringify(v));
            }
            else{
                fData.append(k, v);
            }
        });

        
        axios({
            method: "post",
            url: "erfq/form/",
            data: fData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
            setFormData(initForm);
            setAlertMessage({type:'success', message:response.data.message});
            UpdateBearer(history, response);
            setDisableSubmit(false);
            fileInput.current[0].value = '';
            PageToTop();
        })
        .catch((e) => {
            UpdateBearer(history, e.response);
            if(e.response.status === 400) {
                setAlertMessage({type:'danger', message:"Error Occured While Submit!"});
                const errorCollection = {};
                ['erfq_form', 'erfq_form_documents', 'erfq_form_items', 'erfq_form_terms_condition'].map( (item) => {
                    const dict = {};
                    for (const [key, value] of Object.entries(eval('e.response.data.' + item))) {
                        if(item == 'erfq_form'){
                            dict[key] = value.join(', ');
                        }else{
                            dict[key] = {}
                            for(const [sub_key, sub_value] of Object.entries(value)){
                                dict[key][sub_key] = sub_value.join(', ');
                            }
                        }
                    }
                    errorCollection[item] = dict;
                });
                setFieldError(Object.assign(filedError, errorCollection));
                
            }
            setDisableSubmit(false);
            PageToTop();
        });
        
    };

    return (
        <div className="container">
            <div className="form-wrapper">
                <div className="form-panel-wrapper">
                    <Notification {...alertMessage}/>
                    <form onSubmit={ onSubmit }>
                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                            <div className="col">
                                <label className="form-label">Bidding Type <sup className="text-danger">*</sup></label>
                                
                                <select name="bidding_type" value={formData.bidding_type} onChange={handleChange} className={"form-select " + errorBorder(filedError.erfq_form.bidding_type) }  >
                                    { renderBuddingType() }
                                </select>
                                { errorText(filedError.erfq_form.bidding_type) }
                            </div>
                            <div className="col">
                                <label className="form-label">Reference Number</label>
                                <input type="text" disabled className="form-control" placeholder="Auto Generate" />
                            </div>
                            <div className="col">
                                <label className="form-label">Start Date <sup className="text-danger">*</sup></label>
                                <DatePicker
                                    className={"form-control " + errorBorder(filedError.erfq_form.start_date)}
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    selected={formData.start_date}
                                    onChange={(date) => setFormData({...formData, 'start_date' : date})}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                                { errorText(filedError.erfq_form.start_date) }
                            </div>
                        </div>

                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                            <div className="col">
                                <label className="form-label">Start Time <sup className="text-danger">*</sup></label>
                                <TimePicker
                                    className={"form-control " + errorBorder(filedError.erfq_form.start_time)}
                                    onChange={(time) => setFormData({...formData, 'start_time' : time ? time : ''})}
                                    value={formData.start_time}
                                    showSecond={false}
                                    use12Hours
                                    format={'h:mm a'}
                                    inputReadOnly
                                />
                                { errorText(filedError.erfq_form.start_time) }
                            </div>
                            <div className="col">
                                <label className="form-label">End Date <sup className="text-danger">*</sup></label>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className={"form-control " + errorBorder(filedError.erfq_form.end_date)}
                                    minDate={new Date()}
                                    selected={formData.end_date}
                                    onChange={(date) => setFormData({...formData, 'end_date' : date})}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                                { errorText(filedError.erfq_form.end_date) }
                            </div>
                            <div className="col">
                                <label className="form-label">End Time <sup className="text-danger">*</sup></label>
                                <TimePicker
                                    className={"form-control " + errorBorder(filedError.erfq_form.end_time)}
                                    onChange={(time) => setFormData({...formData, 'end_time' : time ? time : ''})}
                                    value={formData.end_time}
                                    showSecond={false}
                                    use12Hours
                                    format={'h:mm a'}
                                    inputReadOnly
                                />
                                { errorText(filedError.erfq_form.end_time) }
                            </div>
                            
                        </div>

                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                            <div className="col">
                                <label className="form-label">Date of Finalization</label>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className={"form-control " + errorBorder(filedError.erfq_form.finalize_date)}
                                    minDate={new Date()}
                                    selected={formData.finalize_date}
                                    onChange={(date) => setFormData({...formData, 'finalize_date' : date})}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                                { errorText(filedError.erfq_form.finalize_date) }
                            </div>
                            <div className="col">
                                <label className="form-label">Currency <sup className="text-danger">*</sup></label>
                                <select name="currency" value={formData.currency} className={"form-select " + errorBorder(filedError.erfq_form.currency) } onChange={handleChange}>
                                    { renderCurrencies() }
                                </select>
                                { errorText(filedError.erfq_form.currency) }
                            </div>
                            <div className="col">
                                <label className="form-label">Place of Delivery <sup className="text-danger">*</sup></label>
                                <input type="text" name="delivery_place" className={"form-control " + errorBorder(filedError.erfq_form.delivery_place)} value={formData.delivery_place} onChange={handleChange} />
                                { errorText(filedError.erfq_form.delivery_place) }
                            </div>
                        </div>

                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                            <div className="col">
                                <label className="form-label">Incoterms <sup className="text-danger">*</sup></label>
                                <select className={"form-select " + errorBorder(filedError.erfq_form.incoterms) } name="incoterms" value={formData.incoterms} onChange={handleChange}>
                                    { renderIncoterms() }
                                </select>
                                { errorText(filedError.erfq_form.incoterms) }
                            </div>
                            <div className="col">
                                <label className="form-label">Nature of Bidding <sup className="text-danger">*</sup></label>
                                <select className={"form-select " + errorBorder(filedError.erfq_form.bidding_nature) } name="bidding_nature" value={formData.bidding_nature} onChange={handleChange}>
                                    { renderBiddingNature() }
                                </select>
                                { errorText(filedError.erfq_form.bidding_nature) }
                            </div>
                            <div className="col">
                                <label className="form-label">Subject <sup className="text-danger">*</sup></label>
                                <input type="text" className={"form-control " + errorBorder(filedError.erfq_form.subject)} name="subject" value={formData.subject} onChange={handleChange} />
                                { errorText(filedError.erfq_form.subject) }
                            </div>
                        </div>

                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                            <div className="col">
                                <label className="form-label">PR No</label>
                                <input type="text" maxLength="30" className={"form-control " + errorBorder(filedError.erfq_form.pr_number)} name="pr_number" value={formData.pr_number} onChange={handleChange} />
                                { errorText(filedError.erfq_form.pr_number) }
                            </div>
                            <div className="col">
                                <label className="form-label">PR Date</label>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className={"form-control " + errorBorder(filedError.erfq_form.pr_date)}
                                    minDate={new Date()}
                                    selected={formData.pr_date}
                                    onChange={(date) => setFormData({...formData, 'pr_date' : date})}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                                { errorText(filedError.erfq_form.pr_date) }
                            </div>
                            <div className="col">
                                <label className="form-label">RFQ No</label>
                                <input type="text" maxLength="30" className={"form-control " + errorBorder(filedError.erfq_form.rfq_number)} name="rfq_number" value={formData.rfq_number} onChange={handleChange} />
                                { errorText(filedError.erfq_form.rfq_number) }
                            </div>
                        </div>

                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                            <div className="col">
                                <label className="form-label">Target Delivery Date</label>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className={"form-control " + errorBorder(filedError.erfq_form.delivery_date)}
                                    minDate={new Date()}
                                    selected={formData.delivery_date}
                                    onChange={(date) => setFormData({...formData, 'delivery_date' : date})}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                                { errorText(filedError.erfq_form.delivery_date) }
                            </div>
                            <div className="col">
                                <label className="form-label">Price Validity (in days)<sup className="text-danger">*</sup></label>
                                <input type="number" className={"form-control " + errorBorder(filedError.erfq_form.price_validity)} name="price_validity" value={formData.price_validity} onChange={handleChange}/>
                                { errorText(filedError.erfq_form.price_validity) }
                            </div>
                            <div className="col">
                                <label className="form-label">Specific End Use</label>
                                <input type="text" className={"form-control " + errorBorder(filedError.erfq_form.specific_end_use)} name="specific_end_use" value={formData.specific_end_use} onChange={handleChange}/>
                                { errorText(filedError.erfq_form.specific_end_use) }
                            </div>
                        </div>

                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                            <div className="col">
                                <label className="form-label">Requesting Department</label>
                                <input type="text" className={"form-control " + errorBorder(filedError.erfq_form.requesting_dept)} name="requesting_dept" value={formData.requesting_dept} onChange={handleChange}/>
                                { errorText(filedError.erfq_form.requesting_dept) }
                            </div>
                            <div className="col">
                                <label className="form-label">Prepared by</label>
                                <input type="text" className={"form-control " + errorBorder(filedError.erfq_form.prepared_by)} name="prepared_by" value={formData.prepared_by} onChange={handleChange} />
                                { errorText(filedError.erfq_form.prepared_by) }
                            </div>
                            <div className="col">
                                <label className="form-label">Approved by</label>
                                <input type="text" className={"form-control " + errorBorder(filedError.erfq_form.approved_by)} name="approved_by" value={formData.approved_by} onChange={handleChange} />
                                { errorText(filedError.erfq_form.approved_by) }
                            </div>
                        </div>

                        <div className="form-panel">
                            <div className="row row-cols-1 row-cols-lg-2 mb-3 form-elements">
                                <div className="col-12 form-column">
                                    <div className="row mb-4 row-cols-1 row-cols-lg-3 single-col align-items-lg-end">
                                        <div className="col">
                                            {(() => {
                                                if(filedError.emails){
                                                    return (
                                                        <>{ errorText(filedError.emails) }<br/></>
                                                    );
                                                }
                                            })()}
                                            <label className="form-label">Add Email ID</label>
                                            <input type="email" name="email" ref={emailInput} className={"form-control " + errorBorder(filedError.emails) } />
                                        </div>
                                        <div className="col-1">
                                            <button type="button" className="btn btn-success" onClick={() => {addEmail()}}>Add</button>
                                        </div>
                                        
                                    </div>
                                    <ul className="email-list">
                                        { renderEmails() }
                                    </ul>
                                </div>
                            </div>
                            
                        </div>
                        <div className="table-responsive row">
                            <table className="table align-middle table-bordered table-design">
                                <thead>
                                    <tr>
                                        <td>S.No</td>
                                        <td>Item Code <sup className="text-danger">*</sup></td>
                                        <td>Quantity <sup className="text-danger">*</sup></td>
                                        <td>UOM <sup className="text-danger">*</sup></td>
                                        <td>Description <sup className="text-danger">*</sup></td>
                                        <td>&nbsp;</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    { renderItems() }
                                </tbody>
                            </table>
                        </div>

                        <div className="form-panel">
                            <div className="row row-cols-1 row-cols-lg-2 mb-3 form-elements">
                                <div className="col-12 form-column form-address-panel">
                                    <div className="row mb-2 row-cols-1 row-cols-lg-3 single-col">
                                        <div className="col">
                                            <label className="form-label">File Name</label>
                                        </div>
                                        <div className="col">
                                            <label className="form-label">Documents (.pdf, .jpg, .jpeg, .png, .gif, .doc, .docx, .xls, .xlsx)</label>
                                        </div>
                                        <div className="col-1 empty-col">
                                            &nbsp;
                                        </div>
                                    </div>
                                    { renderDocuments() }
                                </div>
                            </div>
                        </div>
                        

                        <div className="form-panel">
                            <div className="row row-cols-1 row-cols-lg-2 mb-3 form-elements">
                                <div className="col-12 form-column form-address-panel">
                                    <div className="row mb-2 row-cols-1 row-cols-lg-2 single-col">
                                        <div className="col-lg-8">
                                            <label className="form-label">Terms and Conditions <sup className="text-danger">*</sup></label>                                       
                                        </div>
                                    </div>
                                    { renderTermsCondition() }
                                </div>
                            </div>
                        </div>
                        <div className="cta-btn mb-4">
                            <button type="submit" disabled={disableSubmit} className="btn btn-primary button-style">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ErfqAdd;