import React, { useState, useEffect } from "react";
import axios from "axios";
import NavigationMenu from "./NavigationMenu";
import { AuthTokenVerify, UpdateBearer } from "../../reusable/AuthUtils";
import Notification from '../../reusable/Notification';
import ReactPaginate from 'react-paginate';
import { PageToTop } from "../../reusable/CommonUtils";
import { Link } from "react-router-dom";
import { noPermissionTemplate, isPermitted } from "../../reusable/PermissoinUtils";

const PendingList = ({history}) => {
    const [dataList, setDataList] = useState([]);
    const [dataListFetched, setDataListFetched] = useState(false);

    const [alertMessage, setAlertMessage] = useState({});

    const [refreshList, setrefreshList] = useState(true);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [noPermission, setNoPermission] = useState(false);

    const recordsPerPage = 15;

    const initForm = {
    };

    const [formData, setFormData] = useState(initForm);

    useEffect(() => {
        AuthTokenVerify(history);
        PageToTop();
    }, []);

    useEffect(() => {
        if(!refreshList) return;
        formData['page'] = page;
        axios.get("connect/action-pending/", {
            params: formData
        }).then((response) => {
            setDataList(response.data.results);
            setPageCount(Math.ceil(response.data.count / recordsPerPage));
            UpdateBearer(history, response);
            setDataListFetched(true);
        })
        .catch((e) => {
            if (e.response.status === 403) {
                //permission denied
                setNoPermission(true);
            }
        });
    setrefreshList(false);
    }, [refreshList]);

    const respondToRequest = (key, action) => {
        setAlertMessage({});

        const newDataList = [...dataList];

        axios.post("connect/action-pending/", {
            company: newDataList[key].company_id,
            action: action
        }).then((response) => {
            newDataList[key]['action'] = action;
            setDataList(newDataList);
            UpdateBearer(history, response);
        }).catch((e) => {
            setAlertMessage({type:'danger', message:e.response.data.message});
            UpdateBearer(history, e.response);
            PageToTop();
        });
    };

    const dataListRender = () => {
        const content = [];

        if(dataListFetched && dataList.length < 1){
            content.push(
                <a className="list-group-item list-group-item-action flex-column align-items-start">
                    <h5 className="mb-1 text-center">No Records Found</h5>
                </a>
            );
            return content;
        }

        dataList.map( (item, key) => {
            let button = <span>
                    <button
                        onClick={ () => { respondToRequest(key, 1) } } 
                        className="btn btn-primary "
                    >Accept</button>
                    &nbsp;&nbsp;
                    <button
                        onClick={ () => { respondToRequest(key, 2) } } 
                        className="btn btn-warning "
                    >Cancel</button>
                </span>;

            if(item.action){

                let button_type = 'btn-primary';
                let message = 'Accepted';

                if(item.action == 2){
                    button_type = 'btn-warning';
                    message = 'Cancelled';
                }
                
                button = <span>
                    <button 
                        type="button" 
                        className="btn {button_type} "
                    >{message}</button>
                </span>;
            }

            content.push(
                <a className="list-group-item list-group-item-action flex-column align-items-start">
                    <div className="d-flex w-100 justify-content-between">
                        <Link to={"/company-profile/" + item.customer_id}  className="mb-1"><b><span className="badge  bg-primary">{ item.customer_id }</span></b> &nbsp;{ item.company_name }</Link>
                        { button }
                    </div>
                </a>
            );
        });

        return content;
    };

    const handlePageClick = (data) => {
        setPage(data.selected + 1);
        setrefreshList(true);
    };

    const showPagination = () => {
        if (pageCount <= 1) return;
        return <nav>
            <ReactPaginate
                pageClassName={'page-item'}
                previousClassName={'page-item active'}
                nextClassName={'page-item active'}
                pageLinkClassName={'page-link'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                forcePage={page - 1}
                pageRangeDisplayed={recordsPerPage}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
        </nav>;
    };

    if(noPermission){
        return (<div className="container">
            <div className="form-wrapper">
                <NavigationMenu item={ 2 }/>
                <div className="form-panel-wrapper">
                    { noPermissionTemplate() }
                </div>
            </div>
        </div>)
    }

    return (
        <div className="container">
            <div className="form-wrapper">
                <NavigationMenu item={ 2 }/>
                <div className="form-panel-wrapper">
                    <Notification {...alertMessage}/>
                    <div className="list-group">
                    { dataListRender() }
                    </div>
                    <br />
                    { showPagination() }
                </div>
            </div>
       </div>
    );
};
export default PendingList;