import React, { useState, useEffect } from "react";
import axios from "axios";
import { confirm } from '../../reusable/Confirmation';
import { AuthTokenVerify, UpdateBearer } from "../../reusable/AuthUtils";
import Notification, {errorBorder, errorText} from '../../reusable/Notification';
import ReactPaginate from 'react-paginate';
import { PageToTop, tryParseJSONObject } from "../../reusable/CommonUtils";
import { Link, useLocation } from "react-router-dom";
import { noPermissionTemplate, isPermitted } from "../../reusable/PermissoinUtils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import _ from "lodash"

const PoSummary = ({history, setShowLoader}) => {

    const params = new URLSearchParams(useLocation().search);
    const paramPage = params.get('page') ? params.get('page') : 1;
    const paramFilter = tryParseJSONObject(params.get('filter')) ? JSON.parse(params.get('filter')) : {};

    const [refreshList, setrefreshList] = useState(true);
    const [page, setPage] = useState(paramPage);
    const [pageCount, setPageCount] = useState(0);
    const [noPermission, setNoPermission] = useState(false);
    const [companyErfqList, setCompanyErfqList] = useState([]);
    const [filter, setFilter] = useState(paramFilter);
    const [currencies, setCurrencies] = useState([]);
    const [ alertMessage, setAlertMessage ] = useState({});

    const availStatus = {0: 'Created', 1: 'Requested', 2: 'Accepted', 3: 'Rejected'};


    const recordsPerPage = 15;

    useEffect(() => {
        AuthTokenVerify(history);
        PageToTop();
    }, []);

    useEffect(() => {
        axios.get("common/currencies/").then((response) => {
            setCurrencies(response.data);
        }).catch((error) => {
            //
         });
    }, []);

    useEffect(() => {
        history(`/po-summary?page=${page}&filter=${JSON.stringify(filter)}`)
    }, [refreshList]);

    useEffect(() => {
        if(!refreshList) return;

        let filterCopy = _.clone(filter);
        if(filterCopy.po_on_date) {
            filterCopy.po_on_date = format(new Date(filterCopy.po_on_date), "yyyy-MM-dd")
        }
        axios.get(`po/form-list/?page=${page}&filter=${encodeURIComponent(JSON.stringify(filterCopy))}`).then((response) => {
            setCompanyErfqList(response.data.results);
            setPageCount(Math.ceil(response.data.count / recordsPerPage));
            UpdateBearer(history, response);
        }).catch((e) => {
            if(e.response.status === 403) {
                //permission denied
                setNoPermission(true);
            }
        });
    setrefreshList(false);
    }, [refreshList]);


    const filterHandle = (e) => {
        var targetData = '';

        switch (e.target.name) {
            default:
                targetData = e.target.value;
                break;
        }

        setFilter({...filter, [e.target.name] : targetData});
        filterHandleTrigger();
    };

    const filterHandleTrigger = () => {
        setrefreshList(true);
        setPage(1);
    };

    const deleteItem = async (key) => {
        const result = await confirm('Are you sure to delete this?');
        if(!result) return;

        setShowLoader(true);
        axios.delete(`po/form-view/${key}/`)
            .then((response) => {
                setAlertMessage({type:'success', message:response.data.message});
                setrefreshList(true);
                PageToTop();
                setShowLoader(false);
            })
            .catch((e) => {
                setAlertMessage({type:'danger', message:e.response.data.message});
                setrefreshList(true);
                PageToTop();
                setShowLoader(false);
            });
    };

    const getList = () => {
        const content = [];
        companyErfqList.map( (row, key) => {
            content.push(<tr key={key} >
                <td>{ (key + 1) + ( recordsPerPage * (page - 1) ) }</td>
                <td>{ row.status_read }</td>
                <td>
                    {(() => {
                        if (row.reference_no) {
                            return <>{ row.reference_no} </>
                        } else {
                            return <i>Yet to generate</i>
                        }
                    })()}
                </td>
                <td>{ row.po_on_date }</td>
                <td>
                    {(() => {
                        if (row.seller_customer_id) {
                            return <Link to={"/company-profile/" + row.seller_customer_id}><b><span className="badge  bg-primary">{row.seller_customer_id}</span></b> {row.seller_company_name}</Link>
                            
                        }
                    })()} 
                </td>
                <td>{ row.subject }</td>
                <td>{ row.pr_number }</td>
                <td>{ row.rfq_number }</td>
                <td>{ row.currency_read }</td>
                <td>{ row.requesting_dept }</td>
                <td>{ row.prepared_by }</td>
                <td>{ row.approved_by }</td>
                <td width={120} className="action-icons">
                    {(() => {
                        if(isPermitted('site_purchase_order_modify')){
                            if(row.status==0){
                                return <Link to={`/po-edit/${row.pk}`} className="btn btn-primary">
                                    <i className="bi bi-pencil-fill cursor-pointer"></i>
                                </Link>
                            }

                            return <button  title="Not Editable"  type="button" className="btn btn-primary action-disabled">
                                <i className="bi bi-pencil-fill"></i>
                            </button>
                            
                        }
                        
                        return <button  title="No permission"  type="button" className="btn btn-primary action-disabled">
                            <i className="bi bi-pencil-fill"></i>
                        </button>
                    })()}

                    {(() => {
                        if(isPermitted('site_purchase_order_view')){
                            return <Link to={`/po-view/${row.pk}`} className="btn btn-success ms-1">
                                <i className="bi bi-eye-fill cursor-pointer"></i>
                            </Link>
                        }
                        
                        return <button  title="No permission"  type="button" className="btn btn-success ms-1 action-disabled">
                            <i className="bi bi-eye-fill"></i>
                        </button>
                    })()}

                    {(() => {
                        if(isPermitted('site_purchase_order_delete')){
                            if(!row.po_on_date) {
                                return <button onClick={() => deleteItem(row.pk) } type="button" className="btn btn-danger ms-1">
                                    <i className="bi bi-trash-fill"></i>
                                </button>
                            }
                            return <button  title="Not allowed"  type="button" className="btn btn-danger ms-1 action-disabled">
                                <i className="bi bi-trash-fill"></i>
                            </button>
                        }
                        return <button  title="No permission"  type="button" className="btn btn-danger ms-1 action-disabled">
                            <i className="bi bi-trash-fill"></i>
                        </button>
                    })()}
                </td>
            </tr>);
        });

        if(content.length < 1){
            content.push(<tr>
                <td colSpan="11" className="text-center">No records found</td>
            </tr>);
        }

        return content;
    };

    const showPagination = () => {
        if (pageCount <= 1) return;
        return <nav>
            <ReactPaginate
                pageClassName={'page-item'}
                previousClassName={'page-item active'}
                nextClassName={'page-item active'}
                pageLinkClassName={'page-link'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                forcePage={page - 1}
                pageRangeDisplayed={recordsPerPage}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
        </nav>;
    };

    const handlePageClick = (data) => {
        setPage(data.selected + 1);
        setrefreshList(true);
        PageToTop();
    };

    const renderBody = () => {
        return <>
            <div className="title-section mb-4">
                <h2>Purchase Order Management</h2>
                {(() => {
                    if(isPermitted('site_purchase_order_add')){
                        return <Link className="button-style" to="/po-add">Add PO</Link>
                    }
                    
                    return <span title="No permission" className="button-style action-disabled">Add PO</span>
                })()}
            </div>
            <div className="table-responsive row">
                <table className="table align-middle table-bordered table-design">
                    <thead>
                        <tr>
                            <td colSpan={2}>
                                <select name="status" value={filter['status'] ? filter['status'] : ""} onChange={filterHandle} className="form-control">
                                    <option value={""}>--status--</option>
                                    {
                                        Object.keys(availStatus).map((key, index) => ( 
                                            <option value={key}>{ availStatus[key] }</option>
                                        ))
                                    }
                                </select>
                            </td>
                            <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="ref_no" value={filter['ref_no'] ? filter['ref_no'] : ""} /></td>
                            <td>
                                <DatePicker
                                    className={"form-control"}
                                    dateFormat="dd/MM/yyyy"
                                    selected={filter.po_on_date}
                                    onChange={(date) => {
                                        setFilter({...filter, 'po_on_date' : date});
                                        filterHandleTrigger();
                                    }}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                            </td>
                            <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="seller" value={filter['seller']  ? filter['seller'] : "" }  /></td>
                            <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="subject" value={filter['subject']  ? filter['subject'] : "" }  /></td>
                            <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="pr_number" value={filter['pr_number']  ? filter['pr_number'] : ""} /></td>
                            <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="rfq_number" value={filter['rfq_number']  ? filter['rfq_number'] : ""} /></td>
                            <td>
                                <select name="currency" style={{width: 'auto'}} value={filter['currency'] ? filter['currency'] : ""} onChange={filterHandle} className="form-control">
                                    <option value={""}>--currency--</option>
                                    {
                                        currencies.map((row, key) => ( 
                                            <option value={row.pk}>{ row.code }</option>
                                        ))
                                    }
                                </select>
                            </td>
                            <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="request_by" value={filter['request_by']  ? filter['request_by'] : ""} /></td>
                            <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="prepared_by" value={filter['prepared_by']  ? filter['prepared_by'] : ""} /></td>
                            <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="approved_by" value={filter['approved_by']  ? filter['approved_by'] : ""} /></td>
                            <td class="text-center"><button className="btn btn-danger" onClick={() => { setFilter({}); filterHandleTrigger(); }}><i class="bi bi-bootstrap-reboot"></i></button></td>
                        </tr>
                        <tr>
                            <td className="sno-width">S.No</td>
                            <td className="status-width">Status</td>
                            <td className="ref-width">PO No.</td>
                            <td className="ref-width">PO Date</td>
                            <td className="subject-width">Seller Name</td>
                            <td className="subject-width">Subject</td>
                            <td className="prno-width">PR. No.</td>
                            <td className="prno-width">RFQ No.</td>
                            <td>Currency</td>
                            <td>Requesting Dept</td>
                            <td>Prepared by</td>
                            <td>Approved by</td>
                            <td className="status-width prno-width text-center">Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        { getList() }
                    </tbody>
                </table>
                { showPagination() }
            </div>
        </>;
    };

    if(noPermission){
        return noPermissionTemplate();
    }

    return (
        <div className="container">
        <div className="form-wrapper summary-container">
            <Notification {...alertMessage}/>
            { renderBody() }
        </div>
        </div>
    );
};

export default PoSummary;