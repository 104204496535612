import { useState, useEffect } from "react"

const Notification = ({type='success', message}) => {

    const [ showMessage, setShowMessage ] = useState('');
    const [ showType, setShowType ] = useState('');

    const typeHeader = {
        success: 'Success',
        warning: 'Warning',
        danger: 'Error',
        primary: 'Important',
        secondary: 'Optional',
        info: 'Information'
    };

    useEffect(() => {
        setShowMessage(message);
        setShowType(type);
    }, [message, type]);

    const showHeader = () => {
        return typeHeader[showType] + '!';
    };

    return (
        (showMessage) ? <div className={"alert alert-" + type + " alert-dismissible fade show"} role="alert">
            <strong>{showHeader()}</strong> {showMessage}
            <button type="button" className="btn-close" onClick={() => { setShowMessage(''); }} ></button>
        </div> : ''
    )
}

const errorText = (content) => {
        if(content) return <label className="text-danger mt-2">{ content }</label>;
};

const successText = (content) => {
    if(content) return <label className="text-success mt-2">{ content }</label>;
};

const errorBorder = (content) => {
    if(content) return 'error-border';
};

export default Notification
export { 
    errorText,
    errorBorder,
    successText
}