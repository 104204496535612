import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { AuthTokenVerify, UpdateBearer } from "../../reusable/AuthUtils";
import Notification, {errorBorder, errorText} from '../../reusable/Notification';
import { PageToTop, returnOnlyDefined } from "../../reusable/CommonUtils";
import DatePicker from "react-datepicker";
import { confirm } from '../../reusable/Confirmation';
import { format, parse, isValid } from "date-fns";
import { useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import FileUploader from "../../reusable/FileUploader"

import "react-datepicker/dist/react-datepicker.css";
import 'rc-time-picker/assets/index.css';

const PoAdd = ({history, setShowLoader}) => {

    let { id } = useParams();

    // for bootsrap model
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const initFieldError = {
        po_form: {},
        po_form_documents: {},
        po_form_items: {},
        po_form_terms_condition: {}
    };

    const [updateID, setUpdateID] = useState(id??false);
    const [triggerFormRender, setTriggerFormRender] = useState(id?true:false);
    const [ filedError, setFieldError ] = useState(initFieldError);
    const [ alertMessage, setAlertMessage ] = useState({});
    const [ disableSubmit, setDisableSubmit ] = useState(false);

    const [ autoGenPrefixError, setAutoGenPrefixError ] = useState({});
    const [ loadAutoGenPrefix, setLoadAutoGenPrefix ] = useState(true);
    const [ autoGenPrefix, setAutoGenPrefix ] = useState("");
    const initItem = {
        item_code: '',
        item_qty: '',
        item_uom: '',
        description: '',
        item_price: '',
        item_hsn: 0,
        item_gst: 0,
        item_total: '',
    };

    const initDocument = {
        file_name: '',
        document: ''
    };

    const initTermsCondition = {
        terms_condition: ''
    };

    const initForm = {
        bidding_type: '',
        currency: '',
        delivery_place: '',
        qno: '',
        incoterms: '',
        subject: '',
        pr_number: '',
        pr_date: '',
        rfq_number: '',
        delivery_date: '',
        specific_end_use: '',
        requesting_dept: '',
        prepared_by: '',
        approved_by: '',
        items: [initItem],
        documents: [initDocument],
        terms_condition: [initTermsCondition],
        discount: 0,
        discount_type: 1,
        packing: 0,
        packing_type: 1,
        fright: 0,
        fright_type: 1,
        reference_no: '',
        auto_generate: 0,
        pay_term: ''
    };

    const [formMeta, setFormMeta] = useState({
        bidding_type: [],
        incoterms: [],
        item_uom: [],
        currencies: []
    });

    const [poAutoRef, setPoAutoRef] = useState(null);

    const [formData, setFormData] = useState(initForm);

    

    useEffect(() => {
        AuthTokenVerify(history);
        PageToTop();
    }, []);

    
    useEffect(() => {
        axios.get("po/form-meta/").then((response) => {
            setFormMeta(response.data);
            UpdateBearer(history, response);
        }).catch((error) => {

        });
        
    }, []);

    useEffect(() => {
        if(!triggerFormRender) return;
        setShowLoader(true);
        axios.get(`po/form-edit/${updateID}/`).then((response) => {
            let data = response.data;
            data.auto_generate = data.reference_no ? 0 : 1;
            data.reference_no = data.reference_no ?? ''
            let prDate = parse(data.pr_date, "dd-MM-yyyy", new Date());
            let deliveryDate = parse(data.delivery_date, "dd-MM-yyyy", new Date());

            data.pr_date = isValid(prDate) ? prDate : '';
            data.delivery_date = isValid(deliveryDate) ? deliveryDate : '';

            if(data.status!=0){
                history('/po-summary');
            }

            setFormData({...initForm, ...data});
            setShowLoader(false);
        }).catch(() => {
            setShowLoader(false);
        });
    }, [triggerFormRender]);

    useEffect(() => {
        if(!loadAutoGenPrefix){
            return;
        }
        axios.get("po/auto-gen-prefix/").then((response) => {
            setPoAutoRef(response.data);
        }).catch((error) => {
            //
         });
        setLoadAutoGenPrefix(false);
    }, [loadAutoGenPrefix]);


    const renderIncoterms = () => {
        const content = [<option value="0">-select-</option>];
        formMeta.incoterms.map(item => {
            content.push(<option value={item.pk}>{item.meta_value}</option>);
        });
        return content;
    };

    const renderItemUom = () => {
        const content = [<option value="0">-select-</option>];
        formMeta.item_uom.map(item => {
            content.push(<option value={item.pk}>{item.meta_value}</option>);
        });
        return content;
    };

    const renderCurrencies = () => {
        const content = [<option value="0">-select-</option>];
        formMeta.currencies.map(item => {
            content.push(<option value={item.pk}>{item.code} - {item.name}</option>);
        });
        return content;
    };

    const removeItem = async(index) => {
        const result = await confirm('Are you sure to delete this?');
        if(!result) return;
        const temp_list = formData.items;
        temp_list.splice(index, 1);
        setFormData({...formData, items: temp_list});
    };


    const addItem = () => {
        const temp_list = formData.items;
        temp_list.push(initItem);
        setFormData({...formData, items: temp_list});
    };

    const removeDocument = async(index) => {
        const result = await confirm('Are you sure to delete this?');
        if(!result) return;
        const temp_list = formData.documents;
        temp_list.splice(index, 1);
        setFormData({...formData, documents: temp_list});
    };

    const addDocument = () => {
        const temp_list = formData.documents;
        temp_list.push(initDocument);
        setFormData({...formData, documents: temp_list});
    };

    const removeTermsCondition = async(index) => {
        const result = await confirm('Are you sure to delete this?');
        if(!result) return;
        const temp_list = formData.terms_condition;
        temp_list.splice(index, 1);
        setFormData({...formData, terms_condition: temp_list});
    };

    const addTermsCondition = () => {
        const temp_list = formData.terms_condition;
        temp_list.push(initTermsCondition);
        setFormData({...formData, terms_condition: temp_list});
    };

    const handleItemChange = (e, index, round_price=false) => {
        var targetData = '';
        var items = formData.items;
        var item = formData.items[index];

        switch (e.target.name) {
            default:
                targetData = e.target.value;
                break;
        }

        if(round_price) {
            targetData = parseFloat(targetData).toFixed(2);
        }
        
        item[e.target.name] = targetData;

        if(['item_price', 'item_qty'].includes(e.target.name)){
            item['item_total'] = parseFloat(parseFloat(item['item_price']) * parseInt(item['item_qty'])).toFixed(2);
        }

        items[index] = item;
        setFormData({...formData, ['items'] : items});
    };

    const handleAutoGenerate = () => {
        setAutoGenPrefixError({});
        axios({
            method: "post",
            url: "po/auto-gen-prefix/",
            data: {refno_prefix: autoGenPrefix}
        })
        .then((response) => {
            setPoAutoRef(response.data);
            setAutoGenPrefix('');
        }).catch((e) => {
            UpdateBearer(history, e.response);
            if(e.response.status === 400) {
                setAutoGenPrefixError(e.response.data);
            }
        });
    }

    const handleDocumentChange = (e, index) => {
        var targetData = '';

        switch (e.target.name) {
            case "document":
                targetData = e.target.files[0];
                break;
            default:
                targetData = e.target.value;
                break;
        }

        var documents = formData.documents;
        documents[index][e.target.name] = targetData;
        setFormData({...formData, ['documents'] : documents});
    };


    const handleTermsConditionChange = (e, index) => {
        var targetData = '';

        switch (e.target.name) {
            default:
                targetData = e.target.value;
                break;
        }

        var terms_condition = formData.terms_condition;
        terms_condition[index][e.target.name] = targetData;
        setFormData({...formData, ['terms_condition'] : terms_condition});
    };

    const renderItems = () => {
        const content = [];
        let items_total = 0;
        let net_total = 0;

        let inc = 1;
        formData.items.map( (item, index) => {
            items_total += isNaN(parseFloat(item.item_total)) ? 0 : parseFloat(item.item_total);
            content.push(<tr>
                <td className="input-small text-center">
                    { inc }
                </td>
                <td className="input-small">
                    <input type="text" className="form-control input-small" name="item_code" value={item.item_code} onChange={(e) => handleItemChange(e, index) } />
                    { errorText(returnOnlyDefined(filedError, 'object.po_form_items[' + index + '].item_code')) }
                </td>
                
                <td className="input-small">
                    <input type="number" className="form-control" name="item_qty" value={item.item_qty} onChange={(e) => handleItemChange(e, index) }  />
                    { errorText(returnOnlyDefined(filedError, 'object.po_form_items[' + index + '].item_qty')) }
                </td>
                <td className="input-small">
                    <select className="form-select" name="item_uom" value={item.item_uom} onChange={(e) => handleItemChange(e, index) }>
                        { renderItemUom() }
                    </select>
                    { errorText(returnOnlyDefined(filedError, 'object.po_form_items[' + index + '].item_uom')) }
                </td>
                <td className="input-large">
                    <textarea className="form-control" cols="4" name="description" maxLength="500" value={item.description} onChange={(e) => handleItemChange(e, index) }></textarea>
                    { errorText(returnOnlyDefined(filedError, 'object.po_form_items[' + index + '].description')) }
                </td>
                <td className="input-small">
                    <input type="number" className="form-control"  name="item_hsn" value={item.item_hsn} onChange={(e) => handleItemChange(e, index) } />
                    { errorText(returnOnlyDefined(filedError, 'object.po_form_items[' + index + ']["item_hsn"]')) }
                </td>
                <td className="input-small">
                    <input type="number" className="form-control"  name="item_gst" value={item.item_gst} onChange={(e) => handleItemChange(e, index) } />
                    { errorText(returnOnlyDefined(filedError, 'object.po_form_items[' + index + ']["item_gst"]')) }
                </td>
                <td className="input-small">
                    <input type="number" className="form-control" name="item_price" value={item.item_price} onBlur={(e) => handleItemChange(e, index, true) } onChange={(e) => handleItemChange(e, index) } />
                    { errorText(returnOnlyDefined(filedError, 'object.po_form_items[' + index + ']["item_price"]')) }
                </td>
                <td className="input-small">
                    <input type="number" className="form-control" name="item_total" value={Number(item.item_total).toFixed(2)} disabled />
                </td>
                <td className="input-small text-center sym-icon">
                {(() => {
                    if(inc == 1){
                        return (
                            <i className="bi bi-plus-circle" onClick={ () => addItem() }></i>
                        );
                    }

                    return (
                        <>
                            <i className="bi bi-dash-circle" onClick={ () => removeItem(index) }></i>&nbsp;
                            <i className="bi bi-plus-circle" onClick={ () => addItem() }></i>
                        </>
                    );
                })()}
                </td>
            </tr>);
            inc++;
        });

        content.push(<tr>
            <td colSpan="8" className="text-end">Total :</td>
            <td colSpan="2">{ Number(items_total).toFixed(2) }</td>
        </tr>);

        content.push(<tr>
            <td colSpan="8" className="text-end">Packing : &nbsp;&nbsp;&nbsp;
                <label className="cursor-pointer">% <input  type="radio" value={1} onChange={handleChange} checked={formData.packing_type==1} name="packing_type"/></label> &nbsp;&nbsp;&nbsp;
                <label className="cursor-pointer">fixed <input  type="radio" value={2} onChange={handleChange} checked={formData.packing_type==2} name="packing_type"/></label>
            </td>
            <td colSpan="2">
                <input type="number"  className="form-control input-small" name="packing" value={formData.packing >= 0 ? formData.packing : ''} onBlur={(e) => handleChange(e, true) } onChange={handleChange}/>
                
            </td>
        </tr>);

        content.push(<tr>
            <td colSpan="8" className="text-end">Freight : &nbsp;&nbsp;&nbsp;
                <label className="cursor-pointer">% <input  type="radio" value={1} onChange={handleChange} checked={formData.fright_type==1} name="fright_type"/></label> &nbsp;&nbsp;&nbsp;
                <label className="cursor-pointer">fixed <input  type="radio" value={2} onChange={handleChange} checked={formData.fright_type==2} name="fright_type"/></label>
            </td>
            <td colSpan="2">
                <input type="number"  className="form-control input-small" name="fright" value={formData.fright >= 0 ? formData.fright : ''} onBlur={(e) => handleChange(e, true) } onChange={handleChange}/>
            </td>
        </tr>);

        content.push(<tr>
            <td colSpan="8" className="text-end">Discount : &nbsp;&nbsp;&nbsp;
                <label className="cursor-pointer">% <input  type="radio" value={1} onChange={handleChange} checked={formData.discount_type==1} name="discount_type"/></label> &nbsp;&nbsp;&nbsp;
                <label className="cursor-pointer">fixed <input  type="radio" value={2} onChange={handleChange} checked={formData.discount_type==2} name="discount_type"/></label>
            </td>
            <td colSpan="2">
                <input type="number"  className="form-control input-small" name="discount" value={formData.discount >= 0 ? formData.discount : ''} onBlur={(e) => handleChange(e, true) } onChange={handleChange}/>
            </td>
        </tr>);


        let discount = parseFloat(Number(formData.discount).toFixed(2));
        let fright = parseFloat(Number(formData.fright).toFixed(2));
        let packing = parseFloat(Number(formData.packing).toFixed(2));

        if (formData.fright_type==1){
            fright = (items_total/100) * fright;
        }

        if (formData.packing_type==1){
            packing = (items_total/100) * packing;
        }

        if (formData.discount_type==1){
            discount = (items_total/100) * discount;
        }

        if(items_total > 0){
            net_total = (items_total + fright + packing) - discount;
        }

        content.push(<tr>
            <td colSpan="8" className="text-end">Net Total :</td>
            <td colSpan="2">{ Number(net_total).toFixed(2) }</td>
        </tr>);
        
        return content;
    };

    const renderDocuments = () => {
        const content = [];
        formData.documents.map( (item, index) => {
            let fetched_doc = item.pk;
            content.push(
                <div className="row mb-3 row-cols-1 row-cols-lg-3 single-col">
                    {(() => {
                        if(fetched_doc){
                            return <>
                                <div className="col">
                                    <input type="text" className="form-control" readOnly  name="file_name" value={item.file_name} />
                                </div>
                                <div className="col">
                                    <input type="text" className="form-control" readOnly  name="file_name" value={item.document} />
                                </div>
                            </>;
                        }

                        
                        
                        return <>
                            <div className="col">
                                <input type="text" className="form-control"  name="file_name" value={item.file_name} onChange={(e) => handleDocumentChange(e, index) }/>
                                { errorText(returnOnlyDefined(filedError, 'object.po_form_documents[' + index + '].file_name')) }
                            </div>
                            
                            <div className="col">
                                <FileUploader name="document"
                                    reference={index}
                                    onUpload={handleDocumentChange}
                                    fileName={item.document ? item.document.name : null}/>
                                { errorText(returnOnlyDefined(filedError, 'object.po_form_documents[' + index + '].document')) }
                            </div>
                        </>;
                    })()}

                    <div className="col-1 plus-icon">
                        {(() => {
                            return (
                                <span>
                                    <i className="bi bi-dash-circle" onClick={ () => removeDocument(index) }></i>&nbsp;
                                    <i className="bi bi-plus-circle" onClick={ () => addDocument() }></i>
                                </span>
                            );
                        })()}
                    </div>
                </div>
            );
        });
        return content;
    };

    const renderTermsCondition = () => {

        const content = [];
        formData.terms_condition.map( (item, index) => {
            content.push(
                <div className="row mb-3 row-cols-1 row-cols-lg-2 single-col">
                   <div className="col-lg-8">
                        <textarea cols="4" className="form-control" name="terms_condition" 
                        maxLength="500" value={item.terms_condition} 
                        onChange={(e) => handleTermsConditionChange(e, index) }></textarea>
                        { errorText(returnOnlyDefined(filedError, 'object.po_form_terms_condition[' + index + '].terms_condition')) }
                    </div>
                    <div className="col-1 plus-icon">
                        {(() => {
                            if(index == 0){
                                return (
                                    <i className="bi bi-plus-circle" onClick={ () => addTermsCondition() }></i>
                                );
                            }

                            return (
                                <span>
                                    <i className="bi bi-dash-circle" onClick={ () => removeTermsCondition(index) }></i>&nbsp;
                                    <i className="bi bi-plus-circle" onClick={ () => addTermsCondition() }></i>
                                </span>
                            );
                        })()}
                    </div>
                </div>
            );
        });
        return content;
    }

    const handleChange = (e, round_amt=false) => {

        let targetData = e.target.value;
        let targetName = e.target.name;

        if(round_amt) {
            targetData = parseFloat(targetData).toFixed(2);
        }

        let updateData = {...formData, [targetName] : targetData};

        switch (targetName) {
            case "discount":
                if (targetData>100 && formData.discount_type==1){
                    updateData[targetName] = 100;
                }
                break;
            case "discount_type":
                updateData['discount'] = 0;
                break;
            case "fright":
                if (targetData>100 && formData.fright_type==1){
                    updateData[targetName] = 100;
                }
                break;
            case "fright_type":
                updateData['fright'] = 0;
                break;
            case "packing":
                if (targetData>100 && formData.packing_type==1){
                    updateData[targetName] = 100;
                }
                break;
            case "packing_type":
                updateData['packing'] = 0;
                break;
            case "auto_generate":
                updateData[targetName] = e.target.checked ? 1 : 0;
                break;
            default:
                //
                break;
        }

        setFormData(updateData);
    };


    const onSubmit = (e) => {
        e.preventDefault();
        setAlertMessage({});
        setFieldError(initFieldError);
        setDisableSubmit(true);

        let fData = new FormData();
        Object.entries(formData).forEach(([k,v]) => {
            if(k == 'documents'){
                let doc_names = {};
                let docs_pk = [];
                v.map( (item, index) => {
                    if(item.pk){
                        docs_pk.push(item.pk);
                    }else{
                        doc_names[index] = item.file_name;
                        fData.append('doc_files_' + index, item.document);
                    }
                });
                if(docs_pk.length > 0){
                    fData.append('docs_pk', JSON.stringify(docs_pk));
                }
                fData.append('doc_names', JSON.stringify(doc_names));

            }else if(['delivery_date', 'pr_date'].includes(k)){
                   v = v ? format(new Date(v), "yyyy-MM-dd") : '';
                   fData.append(k, v);
            }
            else if(['items', 'terms_condition'].includes(k)){
                fData.append(k, JSON.stringify(v));
            }
            else{
                fData.append(k, v);
            }
        });

        setTriggerFormRender(false);
        setShowLoader(true);
        axios({
            method: "post",
            url: updateID ? `po/form-edit/${updateID}/` : `po/form/`,
            data: fData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
            setShowLoader(false);
            setAlertMessage({type:'success', message:response.data.message});
            UpdateBearer(history, response);
            setDisableSubmit(false);
            PageToTop();
            if(updateID){
                setTriggerFormRender(true);
            }else{
                setFormData(initForm);
                setLoadAutoGenPrefix(true);
            }
        })
        .catch((e) => {
            setShowLoader(false);
            UpdateBearer(history, e.response);
            if(e.response.status === 400) {
                setAlertMessage({type:'danger', message:"Error Occured While Submit!"});
                const errorCollection = {};
                ['po_form', 'po_form_documents', 'po_form_items', 'po_form_terms_condition'].map( (item) => {
                    const dict = {};
                    for (const [key, value] of Object.entries(eval('e.response.data.' + item))) {
                        if(item == 'po_form'){
                            dict[key] = value.join(', ');
                        }else{
                            dict[key] = {}
                            for(const [sub_key, sub_value] of Object.entries(value)){
                                dict[key][sub_key] = sub_value.join(', ');
                            }
                        }
                    }
                    errorCollection[item] = dict;
                });
                setFieldError(Object.assign(filedError, errorCollection));
                
            }
            setDisableSubmit(false);
            PageToTop();
        });
        
    };

    return (
        <div className="container">
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Auto Generate Setting</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <label className="form-label">Prefix <sup className="text-danger">*</sup></label>
                            <input type="text" onChange={(e) => setAutoGenPrefix(e.target.value)} className={"form-control " + errorBorder(autoGenPrefixError.refno_prefix) } name="po_auto_prefix" value={autoGenPrefix} />
                            { errorText(autoGenPrefixError.refno_prefix) }
                        </div>
                    </div>
                        {(() => {
                            if (poAutoRef){
                                return (
                                    <div className="row">
                                        <div className="col mt-4">
                                            * Current Prefix : { poAutoRef.refno_prefix } ({poAutoRef.refno_count})
                                        </div>
                                    </div>
                                );
                            }
                            return null;
                        })()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleAutoGenerate}>
                        Set Prefix
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="form-wrapper">
                <div className="form-panel-wrapper">
                    <Notification {...alertMessage}/>
                    <form onSubmit={ onSubmit }>
                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                            <div className="col">
                                <label className="form-label">Purchase Order Number </label>
                                {(() => {
                                    return <>
                                        &nbsp;&nbsp;&nbsp;<i><label><input type="checkbox" checked={formData.auto_generate} disabled={!poAutoRef} name="auto_generate" onClick={handleChange} /> Auto Generate</label></i>
                                        &nbsp;&nbsp;&nbsp;<i onClick={handleShow} className="bi bi-gear-fill cursor-pointer"></i>
                                        <input placeholder={formData.auto_generate ? 'Auto Generate' : ''} type="text" value={formData.auto_generate ? '' : formData.reference_no} disabled={formData.auto_generate} name="reference_no" onChange={handleChange} className={"form-control " + errorBorder(filedError.po_form.reference_no)} />
                                        { errorText(filedError.po_form.reference_no) }
                                    </>;
                                })()}
                            </div>
                            <div className="col">
                                <label className="form-label">Currency <sup className="text-danger">*</sup></label>
                                <select name="currency" value={formData.currency} className={"form-select " + errorBorder(filedError.po_form.currency) } onChange={handleChange}>
                                    { renderCurrencies() }
                                </select>
                                { errorText(filedError.po_form.currency) }
                            </div>
                            <div className="col">
                                <label className="form-label">Quotation Number <sup className="text-danger">*</sup></label>
                                <input type="text" name="qno" className={"form-control " + errorBorder(filedError.po_form.qno)} value={formData.qno} onChange={handleChange} />
                                { errorText(filedError.po_form.qno) }
                            </div>
                        
                        </div>
                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                            <div className="col">
                                <label className="form-label">Place of Delivery <sup className="text-danger">*</sup></label>
                                <input type="text" name="delivery_place" className={"form-control " + errorBorder(filedError.po_form.delivery_place)} value={formData.delivery_place} onChange={handleChange} />
                                { errorText(filedError.po_form.delivery_place) }
                            </div>
                        
                            <div className="col">
                                <label className="form-label">Incoterms <sup className="text-danger">*</sup></label>
                                <select className={"form-select " + errorBorder(filedError.po_form.incoterms) } name="incoterms" value={formData.incoterms} onChange={handleChange}>
                                    { renderIncoterms() }
                                </select>
                                { errorText(filedError.po_form.incoterms) }
                            </div>
                            <div className="col">
                                <label className="form-label">Subject <sup className="text-danger">*</sup></label>
                                <input type="text" className={"form-control " + errorBorder(filedError.po_form.subject)} name="subject" value={formData.subject} onChange={handleChange} />
                                { errorText(filedError.po_form.subject) }
                            </div>
                        
                        </div>
                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                            <div className="col">
                                <label className="form-label">PR No</label>
                                <input type="text" maxLength="30" className={"form-control " + errorBorder(filedError.po_form.pr_number)} name="pr_number" value={formData.pr_number} onChange={handleChange} />
                                { errorText(filedError.po_form.pr_number) }
                            </div>
                            <div className="col">
                                <label className="form-label">PR Date</label>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className={"form-control " + errorBorder(filedError.po_form.pr_date)}
                                    selected={formData.pr_date}
                                    onChange={(date) => setFormData({...formData, 'pr_date' : date})}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                                { errorText(filedError.po_form.pr_date) }
                            </div>
                            <div className="col">
                                <label className="form-label">RFQ No</label>
                                <input type="text" maxLength="30" className={"form-control " + errorBorder(filedError.po_form.rfq_number)} name="rfq_number" value={formData.rfq_number} onChange={handleChange} />
                                { errorText(filedError.po_form.rfq_number) }
                            </div>
                       </div>
                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                            <div className="col">
                                <label className="form-label">Target Delivery Date</label>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className={"form-control " + errorBorder(filedError.po_form.delivery_date)}
                                    selected={formData.delivery_date}
                                    minDate={new Date()}
                                    onChange={(date) => setFormData({...formData, 'delivery_date' : date})}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                                { errorText(filedError.po_form.delivery_date) }
                            </div>
                            <div className="col">
                                <label className="form-label">Specific End Use</label>
                                <input type="text" className={"form-control " + errorBorder(filedError.po_form.specific_end_use)} name="specific_end_use" value={formData.specific_end_use} onChange={handleChange}/>
                                { errorText(filedError.po_form.specific_end_use) }
                            </div>
                            <div className="col">
                                <label className="form-label">Requesting Department</label>
                                <input type="text" className={"form-control " + errorBorder(filedError.po_form.requesting_dept)} name="requesting_dept" value={formData.requesting_dept} onChange={handleChange}/>
                                { errorText(filedError.po_form.requesting_dept) }
                            </div>
                        </div>
                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                            <div className="col">
                                <label className="form-label">Prepared by</label>
                                <input type="text" className={"form-control " + errorBorder(filedError.po_form.prepared_by)} name="prepared_by" value={formData.prepared_by} onChange={handleChange} />
                                { errorText(filedError.po_form.prepared_by) }
                            </div>
                            <div className="col">
                                <label className="form-label">Approved by</label>
                                <input type="text" className={"form-control " + errorBorder(filedError.po_form.approved_by)} name="approved_by" value={formData.approved_by} onChange={handleChange} />
                                { errorText(filedError.po_form.approved_by) }
                            </div>
                            <div className="col">
                                <label className="form-label">Payment Term <sup className="text-danger">*</sup></label>
                                <input type="text" className={"form-control " + errorBorder(filedError.po_form.pay_term)} name="pay_term" value={formData.pay_term} onChange={handleChange} />
                                { errorText(filedError.po_form.pay_term) }
                            </div>
                        </div>
                        <div className="table-responsive row">
                            <table className="table align-middle table-bordered table-design">
                                <thead>
                                    <tr>
                                        <td width="40">S.No</td>
                                        <td>Item Code <sup className="text-danger">*</sup></td>
                                        <td>Quantity <sup className="text-danger">*</sup></td>
                                        <td>UOM <sup className="text-danger">*</sup> </td>
                                        <td>Description <sup className="text-danger">*</sup></td>
                                        <td>HSN <sup className="text-danger">*</sup></td>
                                        <td>GST <sup className="text-danger">*</sup></td>
                                        <td>Item Price <sup className="text-danger">*</sup></td>
                                        <td>Total Price</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    { renderItems() }
                                </tbody>
                            </table>
                        </div>

                        <div className="form-panel">
                            <div className="row row-cols-1 row-cols-lg-2 mb-3 form-elements">
                                <div className="col-12 form-column form-address-panel">
                                    <div className="row mb-2 row-cols-1 row-cols-lg-3 single-col">
                                        <div className="col">
                                            <label className="form-label">File Name </label>
                                        </div>
                                        <div className="col">
                                            <label className="form-label">Documents  (.pdf, .jpg, .jpeg, .png, .gif, .doc, .docx, .xls, .xlsx)</label>
                                        </div>
                                        <div className="col-1 plus-icon">
                                            <span>
                                                <i className="bi bi-plus-circle" onClick={ () => addDocument() }></i>
                                            </span>
                                        </div>
                                    </div>
                                    { renderDocuments() }
                                </div>
                            </div>
                        </div>
                        

                        <div className="form-panel">
                            <div className="row row-cols-1 row-cols-lg-2 mb-3 form-elements">
                                <div className="col-12 form-column form-address-panel">
                                    <div className="row mb-2 row-cols-1 row-cols-lg-2 single-col">
                                        <div className="col-lg-8">
                                            <label className="form-label">Terms and Conditions <sup className="text-danger">*</sup></label>                                       
                                        </div>
                                    </div>
                                    { renderTermsCondition() }
                                </div>
                            </div>
                        </div>
                        <div className="cta-btn mb-4">
                            <button type="submit" disabled={disableSubmit} className="btn btn-primary button-style">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PoAdd;