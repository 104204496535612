import React from "react";

const PageToTop = () => {
    window.scrollTo(0,0);
};

const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

const returnOnlyDefined = (object, value) => {
    try{
        return eval(value);
    }catch(e){
        return '';
    }
};

const tryParseJSONObject = (jsonString) => {
    try {
        var o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object", 
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return o;
        }
    }
    catch (e) { }

    return false;
};

export {
    PageToTop,
    renderHTML,
    returnOnlyDefined,
    tryParseJSONObject
};